import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';

const categoryStyle = theme => css`
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 3em;
    padding-left: 3em;

    ${theme.mqMax['medium']} {
      padding: 0;
    }

    li {
      flex: 0 1 48%;
      display: flex;
      flex-direction: column;
      max-width: 50%;
      margin-bottom: 2em;

      ${theme.mqMax['medium']} {
        flex: 1 1 auto;
        min-width: 100%;
        margin-bottom: 2em;
      }

      h3 {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      }

      span {
        margin-bottom: 1em;
        padding-left: 0.5em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }

      a {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        box-shadow: 0 0.2em 1em rgba(59, 43, 91, 0.7);
        border-radius: 1em;
        color: ${theme.cBlack};
        background-color: ${theme.cLight};
        font-size: 1.5em;

        &:focus {
          outline: none;
          span {
            outline: 4px solid ${theme.cDark};
          }
        }

        &:hover,
        &:focus {
          background-color: ${theme.cNormal};
          text-decoration: none;
        }

        ${theme.mqMax['medium']} {
          font-size: 1em;
        }
      }

      .gatsby-image-wrapper {
        flex: 1 1 auto;
      }
    }
  }
`;

const Categories = ({ heading, categories }) => (
  <section css={categoryStyle} aria-labelledby="categoriesHeading">
    <h2 id="categoriesHeading">{heading}</h2>
    <ul>
      {categories.map(({ slug, title, thumbnail }) => (
        <li key={slug}>
          <Link to={slug}>
            <h3>
              <span>{title}</span>
              <Img fluid={thumbnail.fluid} alt="" />
            </h3>
          </Link>
        </li>
      ))}
    </ul>
  </section>
);

export default Categories;
