import React from 'react';
import { graphql, Link } from 'gatsby';
import Categories from '../components/Categories';
import Layout from '../components/Layout';
import { LiveMessage } from 'react-aria-live';
import Helmet from 'react-helmet';

const CategoryTemplate = ({ data, location, pageContext }) => {
  const subCategories = data.subCategories.edges.map(({ node }) => ({
    title: node.title,
    slug: `/${node.mainCategory.slug}/${node.slug}`,
    thumbnail: node.thumbnail,
  }));
  return (
    <Layout location={location}>
      <LiveMessage
        message={`Navigated to category ${pageContext.title}`}
        aria-live="assertive"
      />
      <Helmet>
        <title>{`Vestiges of War | ${pageContext.title}`}</title>
      </Helmet>
      <div className="breadcrumb">
        <Link to="/">Home</Link>
      </div>
      <Categories categories={subCategories} heading={pageContext.title} />
    </Layout>
  );
};

export default CategoryTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    subCategories: allDatoCmsSubCategory(
      filter: { mainCategory: { id: { eq: $id } } }
    ) {
      edges {
        node {
          id
          title
          slug
          thumbnail: image {
            url
            fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          mainCategory {
            title
            slug
          }
        }
      }
    }
  }
`;
